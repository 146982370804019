import Vue from "vue";
import VueRouter from "vue-router";

import WebHome from "../views/WebHome.vue";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "webhome",
    component: WebHome,
    meta: { title: "juqidianzi" },
  },
  {
    path: "/web/home",
    name: "webhome",
    component: WebHome,
    meta: { title: "juqidianzi" },
  }

];

const router = new VueRouter({
  //mode: "hash",
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
