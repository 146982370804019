const address = {
  state: {
    countryKey: 1,
    provinceKey: null,
    cityKey: null
  },
  mutations: {
    SET_PROVINCE_KEY: (state, key) => {
      state.provinceKey = key;
    },
    SET_CITY_KEY: (state, key) => {
      state.cityKey = key;
    }
  },
  actions: {}
};

export default address;
