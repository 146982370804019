import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'normalize.css/normalize.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueStorage from "vue-ls";

import vueWechatTitle from "vue-wechat-title";
Vue.use(vueWechatTitle);

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(VueStorage, {
  namespace: "knowledge_web__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local" // storage name session, local, memory
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
