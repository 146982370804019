import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import address from "./modules/address";
import getters from "./getters";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    user,
    address
  },
  state: {
    isCollapse: false,
    nowMenuKey: "system",
    serviceName: ""
  },
  mutations: {
    SET_ISCOLLAPSE: (state, param) => {
      state.isCollapse = param;
    },
    SET_NOWMENUKEY: (state, param) => {
      Vue.ls.set("nowMenuKey", param, null);
      state.nowMenuKey = param;
    },
    SET_SERVICENAME: (state, param) => {
      state.serviceName = param;
    }
  },
  actions: {},
  getters
});
