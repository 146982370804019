<template>
  <div>
    <div class="top_area">
      <div class="company_name_area">
        <h1>
          <span>聚奇电子</span>
        </h1>
      </div>
      <div class="top_area_content">
        <div class="top_area_content_left">
          <div class="top_area_content_left_h2">
            <h2>Electronic</h2>
          </div>
          <div class="top_area_content_left_h3">
            <h3>commerce</h3>
          </div>
          <div class="top_area_content_left_content">
            Our business is helping you grow your business. We offer Best Web &
            Applications Solutions, Project Management, Software Update, Google
            Cloud, SEO optmization, Social Media Services...
          </div>
        </div>
        <div class="top_area_content_right">
          <img src="@/assets/hero.png" class="logo-img" />
        </div>
      </div>
    </div>
    <div class="middle_area_content">
      <div class="middle_top">
        <h2>What we do?</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      </div>
      <div class="middle_content">
        <div class="middle_content_grid">
          <div class="middle_content_grid_left">
            <div class="middle_content_grid_icon">
              <i class="el-icon-edit"></i>
            </div>
            <div class="middle_content_grid_title">
              Manufacturing
            </div>
            <div class="middle_content_grid_content">
              制造与生产
            </div>
          </div>
          <div class="middle_content_grid_middle">
            <div class="middle_content_grid_icon">
              <i class="el-icon-pie-chart"></i>
            </div>
            <div class="middle_content_grid_title">
              Logistics
            </div>
            <div class="middle_content_grid_content">
              物流与运输
            </div>
          </div>
          <div class="middle_content_grid_right">
            <div class="middle_content_grid_icon">
              <i class="el-icon-notebook-1"></i>
            </div>
            <div class="middle_content_grid_title">
              Intelligent
            </div>
            <div class="middle_content_grid_content">
              智能供应链
            </div>
          </div>
        </div>



        <div class="middle_content_grid" style="margin-top:20px;">
          <div class="middle_content_grid_left">
            <div class="middle_content_grid_icon">
              <i class="el-icon-setting"></i>
            </div>
            <div class="middle_content_grid_title">
              Software
            </div>
            <div class="middle_content_grid_content">
              服务支撑
            </div>
          </div>
          <div class="middle_content_grid_middle">
            <div class="middle_content_grid_icon">
              <i class="el-icon-goods"></i>
            </div>
            <div class="middle_content_grid_title">
              Quality
            </div>
            <div class="middle_content_grid_content">
              质量保证
            </div>
          </div>
          <div class="middle_content_grid_right">
            <div class="middle_content_grid_icon">
              <i class="el-icon-eleme"></i>
            </div>
            <div class="middle_content_grid_title">
              24/7 Support
            </div>
            <div class="middle_content_grid_content">
              全天在线
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="foot_area">
      <h3>Read to start something great?</h3>
    </div>

    <div class="declare_ipc">
      <div>
        Copyright © 2022-2023 深圳市聚奇电子有限公司 保留所有权利。
      </div>
      <div>
        深圳市龙岗区园山街道大康社区莘塘安兴路44号（鸿浩创富智园）B栋503
      </div>
      <div>
        联系电话：18011762289
      </div>
      <div>
        <!-- 粤ICP备2023003843号 -->
        <!-- <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502003224" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
          <img src="@/assets/icplogo.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">粤公网安备 44060502003224号</p></a> -->
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702005290"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/icplogo.png"
              style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;">粤公网安备
              44030702005290号</p>
          </a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  mounted() {
    //let productList =axios.get("/api/product/search", { params: { name: "33333" } });
  },
  created() { },
  components: {},
  filters: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.top_area {
  width: 100%;
  position: relative;
  background: url(~@/assets/hero-bg.png) center center;
  background-size: cover;
  padding: 60px 0 0 0;
  height: 100vh;
}

.company_name_area {
  padding-left: 100px;
}

.company_name_area h1 {
  color: white;
}

.company_name_area h1 span {
  color: #f05454;
}

.top_area_content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.top_area_content_left {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 100px;
}

.top_area_content_right {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.top_area_content_left_h2 {
  display: flex;
  flex-direction: row;
  font-size: 98px;
  font-weight: 900;
  margin-bottom: 0px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  height: 250px;
}

.top_area_content_left_h3 {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 63px;
  line-height: 60px;
  font-weight: 300;
  text-transform: uppercase;
  height: 150px;
}

.top_area_content_left_content {
  display: flex;
  flex-direction: row;
  color: rgb(197 197 197 / 80%);
  font-weight: 400;
  margin-bottom: 80px;
  width: 700px;
}

.middle_area_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-items: center;
}

.middle_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-items: center;
}

.middle_top h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #f05454;
}

.middle_top p {
  color: #ffffff;
}

.middle_content {
  display: flex;
  flex-direction: column;
  width: 80%;
  color: #ffffff;
}

.middle_content_grid {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.middle_content_grid_left {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #f05454;
  align-items: center;
  justify-items: center;
  height: 250px;
  margin-right: 10px;
}

.middle_content_grid_icon {
  display: flex;
  flex-direction: column;
  font-size: 50px;
  height: 50%;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.middle_content_grid_title {
  display: flex;
  flex-direction: column;
  font-size: 40px;
  height: 30%;
  align-items: center;
  justify-items: center;
}

.middle_content_grid_content {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  height: 40%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}



.middle_content_grid_middle {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #f05454;
  align-items: center;
  justify-items: center;
  height: 250px;
  margin-right: 10px;
}


.middle_content_grid_right {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #f05454;
  align-items: center;
  justify-items: center;
  height: 250px;
  margin-right: 10px;
}

.foot_area {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f05454;
  margin-top: 30px;
  height: 200px;
  align-items: center;
  justify-items: center;
  justify-content: center;

  color: #fff;
  font-size: 30px;
  font-weight: 300;
}

.declare_ipc {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  margin-top: 20px;
  text-align: center;
}</style>
