/*
 * @Descripttion:
 * @version:
 * @Author: zengjingke
 * @Date: 2020-04-17 10:09:19
 * @LastEditors: zengjingke
 * @LastEditTime: 2020-06-08 17:26:14
 */
const getters = {
  // device: state => state.app.device,
  // theme: state => state.app.theme,
  // color: state => state.app.color,
  token: state => state.user.token,
  accountId: state => state.user.accountId,
  employeeId: state => state.user.employeeId,
  employeeName: state => state.user.employeeName,
  loginName: state => state.user.loginName,
  avatar: state => state.user.avatar,
  language: state => state.user.language,
  routerMap: state => state.user.routerMap,
  companyId: state => state.user.companyId,
  companyName: state => state.user.companyName,
  roleTypeList: state => state.user.roleTypeList,
  isCollapse: state => state.isCollapse,
  nowMenuKey: state => state.nowMenuKey,
  serviceName: state => state.serviceName,
  addRoutes: state => state.user.addRoutes,
  provinceKey: state => state.address.provinceKey,
  cityKey: state => state.address.cityKey
  // avatar: state => state.user.avatar,
  // nickname: state => state.user.name,
  // welcome: state => state.user.welcome,
  // roles: state => state.user.roles,
  // userInfo: state => state.user.info,
  // addRouters: state => state.permission.addRouters,
  // multiTab: state => state.app.multiTab
};

export default getters;
