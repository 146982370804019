<template>

  <div id="app">
    <div id="nav">
       <router-view v-wechat-title="$route.meta.title" /> 
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <!-- <foot></foot> -->
    <!-- <router-view /> -->
  </div>
</template>


<script>
//import foot from "@/components/footer3.vue";
export default {
  
  data() {
    return {
      
    };
  },
  components: {
    //foot,
  },
};
</script>


<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #373737;
}

#nav {
  // padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.top_search{
  padding: 10px;
}
</style>
